import React, { useState, useEffect } from "react";
import PersonSchema from "../seo/PersonSchema";
import DarkBackground from './DarkBackground';
import '../css/darkTheme.css';
import '../css/Skills.css';

const Skills = () => {
  const [animatedValues, setAnimatedValues] = useState({});
  const [showPercentages, setShowPercentages] = useState(true);

  const skills1 = [
    { id: "HTML5_skill", content: "Web tools: HTML, CSS, Bootstrap, ReactStrap, Sematic UI ", porcentage: "80%", value: "80" },
    { id: "CSS3_skill", content: "Javascript,java,python", porcentage: "75%", value: "75" },
    {
      id: "JavaScript_skill",
      content: "React JS, React hooks, Redux, Redux-form",
      porcentage: "90%",
      value: "90"
    },
    { id: "PHP_skill", content: "Nodejs,expressJS ", porcentage: "70%", value: "70" },
    {
      id: "ReactJS_skill",
      content: "DBMS: MySQL, SQL Server, Oracle",
      porcentage: "80%",
      value: "80"
    },
    {
      id: "Python_skill",
      content: "XML,C",
      porcentage: "75%",
      value: "75"
    },
    {
      id: "VanillaJS_skill",
      content: "Web Services: SOAP,REST",
      porcentage: "85%",
      value: "85"
    },
    {
      id: "Wordpress_skill",
      content: "Spring boot,JWT",
      porcentage: "80%",
      value: "80"
    }
  ];

  const skills2 = [
    { id: "HTML5_skill3dx", content: "Modeling: UML, Merise,Design patter", porcentage: "80%", value: "80" },
    { id: "HTML5_skill3dx", content: "Devops:Jenkins , Docker ,Gitlab CI/CD, Github Actoins", porcentage: "80%", value: "80" },
    { id: "HTML5_skill", content: "3Dexperience,JPO,FMEA, Testability MQL, EKL,BL ", porcentage: "65%", value: "80" },
    {
      id: "JavaScript_skill",
      content: "Temenos T24:Customization&Local development,OFS, BATCH",
      porcentage: "90%",
      value: "90"
    },
    {
      id: "Wordpress_skill",
      content: "SAFe Framework, Scrum, Jira, and Confluence Management ",
      porcentage: "80%",
      value: "80"
    },
    {
      id: "Wordpress_skill",
      content: "OS:  Windows/Linux/Unix administration",
      porcentage: "80%",
      value: "80"
    },
    {
      id: "Wordpress_skill",
      content: "Testing: Selinum ,TestNG, JMeter ",
      porcentage: "80%",
      value: "80"
    },
    {
      id: "Wordpress_skill",
      content: "React Native, SonarQube ,GitHub/Git,ESB",
      porcentage: "80%",
      value: "80"
    }
  ];

  useEffect(() => {
    const animateProgress = () => {
      const allSkills = [...skills1, ...skills2];
      let completedAnimations = 0;
      
      allSkills.forEach(skill => {
        let startValue = 0;
        const endValue = parseInt(skill.value);
        const duration = 1200;
        const increment = endValue / (duration / 16);
        
        const timer = setInterval(() => {
          startValue += increment;
          
          if (startValue >= endValue) {
            startValue = endValue;
            clearInterval(timer);
            completedAnimations++;
            
            // When all animations are complete, hide the percentages
            if (completedAnimations === allSkills.length) {
              setTimeout(() => {
                setShowPercentages(false);
              }, 200); // Small delay after completion
            }
          }
          
          setAnimatedValues(prev => ({
            ...prev,
            [skill.id]: startValue
          }));
        }, 16);
      });
    };

    // Start animation when component mounts
    animateProgress();
  }, []);

  const renderSkill = (skill) => (
    <React.Fragment key={skill.id}>
      <span className="skill-title">{skill.content}</span>{" "}
      <span className="pull-right percentage">
        {Math.round(animatedValues[skill.id] || 0)}%
      </span>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${animatedValues[skill.id] || 0}%` }}
          aria-valuenow={animatedValues[skill.id] || 0}
          aria-valuemin="0"
          aria-valuemax="100"
        >
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <DarkBackground>
      <section id="skills" className="skills-section route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <h5 style={{color:"orange"}} className="title-left">Skills</h5>
                <div className="row skills-container">
                  <div className="col-md-6">
                    <div className="skill-mf">
                      {skills1.map(renderSkill)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="skill-mf">
                      {skills2.map(renderSkill)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PersonSchema/>
      </section>
    </DarkBackground>
  );
};

export default Skills;
