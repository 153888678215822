import React, { useState, useEffect } from "react";
import DarkBackground from './DarkBackground';
import ExperienceMobile from './ExperienceMobile';
import ExperienceDesktop from './ExperienceDesktop';
import "../css/darkTheme.css";
import "./../css/Exp.css";
import PersonSchema from "../seo/PersonSchema";

const Experience = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <DarkBackground>
      <section id="experience" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              
                <div className="title-left">
                  <h2>Experience</h2>
                </div>
                
                {isMobile ? <ExperienceMobile /> : <ExperienceDesktop />}
              </div>
            
          </div>
        </div>
        <PersonSchema/>
      </section>
    </DarkBackground>
  );
};

export default Experience;
