import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import SeoWrapper from './seo/SeoWrapper';
import BackHome from './components/back-home';

//import css in order 
import 'normalize.css';
import './animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import './img/icons/css/ionicons.css';
import './img/font-awesome/css/font-awesome.css';
import 'lightbox2/dist/css/lightbox.min.css'
import './style.css';

//import js libraries
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './libs/easing.js';
import 'lightbox2/dist/js/lightbox.min.js';

import * as serviceWorker from './serviceWorker';

//import components
import Navbar from './components/navbar.jsx';
import Intro from './components/intro.jsx';
import About from './components/about.jsx';
import Portfolio from './components/portfolio.jsx';
import Contact from './components/contact.jsx';
import BackToTop from './components/back-top.jsx';
import Preloader from './components/preloader';
import Skills from './components/skills.jsx'
import Experience from './components/experience.jsx';
import Blog from './components/blog';
import ArticleDetail from './components/article-detail';
import BankingSolutions from './components/BankingSolutions';
import FreelanceServices from './components/FreelanceServices';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Footer from './components/Footer';
import Testimonials from './components/Testimonials';

// Create a Home component that contains the main page content
const Home = () => (
  <React.Fragment>
    <Intro />
  </React.Fragment>
);

// Modified Layout component to handle footer visibility
const Layout = ({ children }) => {
  const location = useLocation();
  const shouldShowFooter = !location.pathname.includes('/blog') && 
                          location.pathname !== '/skills';
  
  return (
    <React.Fragment>
      <SeoWrapper path={location.pathname} />
      <Navbar />
      {children}
      {shouldShowFooter && <Footer />}
      <BackToTop />
      <Preloader />
      <BackHome />
    </React.Fragment>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<ArticleDetail />} />
          <Route path="/banking-solutions" element={<BankingSolutions />} />
          <Route path="/freelance-services" element={<FreelanceServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Routes>
      </Layout>
    </Router>
  </React.StrictMode>
);

serviceWorker.unregister();

