import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const currentPath = window.location.pathname;
  
  // Return null if we're on the skills page or any blog page
  if (currentPath === '/skills' || currentPath.includes('/blog')) {
    return null;
  }

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-text">
          © {currentYear}
          <span className="separator"> | </span>
          Made with <span className="heart">♥</span> by <a 
            href="https://github.com/zeglami" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            @zeglami
          </a>
          <span className="separator"> | </span>
        </div>
        <div className="footer-links">
          <Link to="/testimonials">Testimonials</Link>
          <span className="separator"> | </span>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span className="separator"> | </span>
          <Link to="/terms">Terms of Use</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 