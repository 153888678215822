import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faUniversity, faSchool, faMobile } from "@fortawesome/free-solid-svg-icons";
import "../css/darkTheme.css";
import "./../css/Exp.css";

class ExperienceDesktop extends React.Component {
  constructor() {
    super();
    this.state = {
      // Your state data remains unchanged
    };
  }

  render() {
    return (
      <div className="box-shadow-full">
        <VerticalTimeline
          animate={true}
          layout="1-column-left"
          lineColor={"#f27500"}
        >
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            icon={
              <FontAwesomeIcon
                icon={faUniversity}
                style={{ color: "#f27500" }}
              />
            }
            contentArrowStyle={{
              borderRight: "7px solid  rgb(33, 150, 243)",
            }}
            date="April 2024 - Now"
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Banking
            </h4>
            <div className="container mt-4">
              <h2>Projects</h2>
              
              <h3>1. Customer Onboarding Digitalization</h3>
              <p><strong>Context:</strong> Enable individuals to become customers through the mobile application</p>
              <p><strong>Team:</strong> 1 PO + 1 functional + 6 developers + Tech Leader + 1 Scrum master</p>
              <p><strong>Methodology:</strong> Agile / 2-week sprints</p>
              <h4>Achievements:</h4>
              <ul>
                <li>Detailed technical specifications and documentation</li>
                <li>Task creation, breakdown, estimation, and issue management</li>
                <li>Task management using Teams Task/Mantis</li>
                <li>Deliverables monitoring and quality assurance</li>
                <li>Front-end and back-end (T24) team coordination</li>
                <li>Web services performance optimization</li>
                <li>Deployment from Dev to staging environment</li>
                <li>Implementation of unit and integration tests</li>
                <li>Code review participation</li>
                <li>User feedback analysis for experience improvement</li>
                <li>Security compliance collaboration</li>
              </ul>

              <h3>2. GitLab Migration Project</h3>
              <p><strong>Context:</strong> Migration from CentOS 7 to Debian 13 and version upgrade (13 to 17)</p>
              <h4>Achievements:</h4>
              <ul>
                <li>Successfully migrated GitLab to Debian 13</li>
                <li>Upgraded GitLab from version 13 to 17</li>
                <li>Development monitoring and quality assurance</li>
                <li>Created comprehensive documentation and user guides</li>
                <li>Conducted user audits for access optimization</li>
                <li>Managed GitLab administration and permissions</li>
                <li>Provided user training on new features</li>
                <li>Monitored post-migration performance</li>
                <li>Implemented data integrity tests</li>
                <li>Setup deployment pipeline (ongoing)</li>
              </ul>

              <h3>3. T24 Production Support</h3>
              <p><strong>Context:</strong> Technical and functional support for T24 users</p>
              <h4>Achievements:</h4>
              <ul>
                <li>Provided technical support to users</li>
                <li>Resolved blocking situations</li>
                <li>Developed fixes for identified bugs</li>
                <li>Ensured T24 services availability</li>
              </ul>

              <h2>Tools</h2>
              <p>T24 (VERSION, ENQUIRY, TAFJ), Design Studio, Java, Autoform Rest API, GitLab, Git, Debian, CentOS</p>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            icon={
              <FontAwesomeIcon
                icon={faPlane}
                style={{ color: "#f27500" }}
              />
            }
            date="February 2022 - January 2024"
          >
            <h3 className="vertical-timeline-element-title">
              Research & Development Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Airbus
            </h4>
            <div className="container mt-4">
              <h2>Key points</h2>
              <ul>
                <li>PLM Improvement/Impact Evaluation of Changes</li>
                <li>SAFe Framework, Scrum, Jira, and Confluence Management</li>
                <li>Developing Java (JPO) for Document Control Check</li>
                <li>Developing FMEA, DEFMEA, PFMEA components</li>
                <li>Performance Optimization</li>
                <li>Testing and Continuous Integration</li>
                <li>Source Code Quality Control</li>
              </ul>
              <h2>Tools</h2>
              <p>Java 11/17, JPO, Testability, Javascript MQL, Mobaxterm, SonarQube, CSV, JSON, GitLab, DevOps, Junit, Mockito, Gradle, 3dexperience, EKL, VS Code, IntelliJ IDEA, Jira, Confluence, Selenium, TestNG, Extent Report</p>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            icon={
              <FontAwesomeIcon
                icon={faUniversity}
                style={{ color: "#f27500" }}
              />
            }
            date="September 2019 - January 2022"
          >
            <h3 className="vertical-timeline-element-title">
              Research & Development Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              BCP Technologies
            </h4>
            <div className="container mt-4">
              <h2>Key Points</h2>
              <ul>
                <li>Writing and updating Functional Requirements Specifications</li>
                <li>Backend Development: microservices, restful web services</li>
                <li>Frontend Development: Created interfaces using React.js, managed states with Redux</li>
                <li>Testing and CI/CD: Utilized GitLab for DevOps deployment, managed CI/CD pipelines on Docker, and ensured high code quality with SonarQube</li>
                <li>Performance Testing: Conducted performance testing with Apache JMeter, ensuring the platform's robustness</li>
                <li>Scrum Methodology: Led Scrum methodology rituals and managed sprints effectively</li>
              </ul>
              <h2>Tools</h2>
              <p>ReactJS, NodeJS, Spring boot, Temenos T24</p>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="1 February 2019 - 28 June 2019"
            icon={
              <FontAwesomeIcon
                icon={faUniversity}
                style={{ color: "#f27500" }}
              />
            }
          >
            <h3 className="vertical-timeline-element-title">
              Full stack developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Banque Centrale Populaire (BCP)
            </h4>
            <div className="container mt-4">
              <h2>Key points</h2>
              <ul>
                <li>Digitalization Leadership</li>
                <li>Client Needs Analysis</li>
                <li>Functional and technical specifications</li>
                <li>Frontend Development with React.js</li>
                <li>Responsive Interface Design</li>
                <li>Backend Development with Spring Boot</li>
                <li>Unit Testing with TestNG</li>
              </ul>
              <h2>Tools</h2>
              <p>Java 8, React.js, PowerDesigner, Spring boot, REST API, Hibernate, Oracle 11g, Reactstrap, GitHub, Tomcat, JUnit</p>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="1 July 2018 - 31 August 2018"
            icon={
              <FontAwesomeIcon
                icon={faMobile}
                style={{ color: "#f27500" }}
              />
            }
          >
            <h3 className="vertical-timeline-element-title">
              Full stack developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              INTELLCAP
            </h4>
            <div className="container mt-4">
              <h2>Key points</h2>
              <ul>
                <li>Mobile app(ios and android) for an Environmental Education Center</li>
                <li>Mobile App Development: React Native, Expo XDE</li>
                <li>Backend Development: Symfony</li>
                <li>Database Management: SQL Server</li>
              </ul>
              <h2>Tools</h2>
              <p>Expo XDE, React Native, Symfony, SQL Server</p>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="10 July 2016 - 31 August 2016"
            icon={
              <FontAwesomeIcon
                icon={faSchool}
                style={{ color: "#f27500" }}
              />
            }
          >
            <h3 className="vertical-timeline-element-title">
              Full stack developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Phoenix IT Company
            </h4>
            <div className="container mt-4">
              <h2>Key points</h2>
              <ul>
                <li>Platform learning for employees</li>
                <li>Instructor Space/Employee Space/Admin Space</li>
              </ul>
              <h2>Tools</h2>
              <p>ReactJS, NodeJS, Express.js, MySQL</p>
            </div>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    );
  }
}

export default ExperienceDesktop;