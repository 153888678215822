import React, { useState, useEffect } from 'react';
import PersonSchema from "../seo/PersonSchema";
import BlogSeo from "../seo/BlogSeo";
import axios from 'axios';
import '../css/Blog.css';
import '../css/darkTheme.css';
import fallbackIcon from '../img/blog/iconLoading.png';
import { useNavigate, useLocation } from 'react-router-dom';
import DarkBackground from './DarkBackground';

const BackToList = ({ onClick }) => {
  return (
    <button 
      onClick={onClick}
      className="back-to-list animated"
      style={{
        position: 'fixed',
        left: '50%',
        bottom: '0rem',
        transform: 'translateX(-50%)',
        zIndex: 99,
        padding: '0.5rem 1.5rem',
        backgroundColor: '#00b0ff',
        border: 'none',
        borderRadius: '30px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        cursor: 'pointer',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        fontSize: '0.9rem',
        fontWeight: '500'
      }}
    >
      <i className="bi bi-arrow-left"></i>
      Back to Articles
    </button>
  );
};

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getFirstImageUrl = (content) => {
    // Try to find image in figure tag first
    const figureMatch = content.match(/<figure><img[^>]*src="([^"]*)"[^>]*>/);
    if (figureMatch) return figureMatch[1];

    // Try to find image in feed-image class
    const feedImageMatch = content.match(/<p class="medium-feed-image"><a[^>]*><img[^>]*src="([^"]*)"[^>]*>/);
    if (feedImageMatch) return feedImageMatch[1];

    // Fallback to any image
    const imgMatch = content.match(/<img[^>]*src="([^"]*)"[^>]*>/);
    if (imgMatch) return imgMatch[1];

    // Return fallback image if no image found
    return fallbackIcon;
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@zeglami`
        );
        
        if (response.data.status === 'ok') {
          const articlesWithImages = response.data.items.map(article => ({
            ...article,
            firstImage: getFirstImageUrl(article.content)
          }));
          
          setArticles(articlesWithImages);
        } else {
          throw new Error('Failed to fetch articles');
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Fetch error:', err);
        setError('Failed to fetch articles');
        setShowErrorPopup(true);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const handleArticleClick = (article) => {
    const articleId = article.guid.split('/').pop();
    navigate(`/blog/${articleId}`, {
      state: { articleTitle: article.title }
    });
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    navigate('/blog');
    window.scrollTo(0, 0);
  };

  const handleMediumRedirect = () => {
    window.open('https://medium.com/@zeglami', '_blank');
  };

  // Check if we're in article view
  const isArticleView = location.pathname === '/blog/article';
  const articleToShow = location.state?.article;

  if (loading) {
    return (
      <DarkBackground>
        <div className="blog-loading">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </DarkBackground>
    );
  }

  if (error) {
    return (
      <DarkBackground>
        <div className="blog-error">
          {showErrorPopup && (
            <div className="error-popup">
              <div className="error-popup-content">
                <h3>Oops! Something went wrong</h3>
                <p>We couldn't load the articles from Medium.</p>
                <button 
                  className="btn btn-primary"
                  onClick={handleMediumRedirect}
                >
                  Visit My Medium Profile
                </button>
                <button 
                  className="btn btn-secondary mt-2"
                  onClick={() => setShowErrorPopup(false)}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </DarkBackground>
    );
  }

  if (isArticleView && articleToShow) {
    return (
      <DarkBackground>
        <section className="article-detail sect-pt4">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="article-header">
                  <h1 className="article-title">{articleToShow.title}</h1>
                  <div className="article-meta">
                    <span className="article-date">
                      {new Date(articleToShow.pubDate).toLocaleDateString()}
                    </span>
                    <span className="article-author">
                      <i className="bi bi-person"></i> {articleToShow.author}
                    </span>
                  </div>
                </div>
                
                {articleToShow.thumbnail && (
                  <div className="article-image">
                    <img 
                      src={articleToShow.thumbnail} 
                      alt={articleToShow.title}
                      className="img-fluid"
                    />
                  </div>
                )}
                
                <div 
                  className="article-content"
                  dangerouslySetInnerHTML={{ __html: articleToShow.content }}
                />
                
                <div className="article-footer">
                  <a 
                    href={articleToShow.link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-primary"
                  >
                    Read on Medium
                  </a>
                </div>
              </div>
            </div>
          </div>
          <BackToList onClick={handleBack} />
        </section>
      </DarkBackground>
    );
  }

  return (
    <DarkBackground>
      <section id="blog" className="blog-mf sect-pt4 route">
        <BlogSeo />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h5 style={{color:"orange"}} className="title-left">Blog</h5>
              <p className="subtitle-a">Latest articles and tech insights</p>
              <div className="box-shadow-full">
                <div className="row">
                  {articles.map((article, index) => {
                    return (
                      <div key={index} className="col-md-4">
                        <div className="card blog-card" onClick={() => handleArticleClick(article)}>
                          <div className="card-img">
                            <img 
                              src={getFirstImageUrl(article.description || article.content)} 
                              alt={article.title}
                              className="img-fluid"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = fallbackIcon;
                              }}
                            />
                          </div>
                          <div className="card-body">
                            <div className="card-category-box">
                              <div className="card-category">
                                <h6 className="category">Medium</h6>
                              </div>
                            </div>
                            <h3 className="card-title">
                              <span style={{ cursor: 'pointer', color: '#333' }}>
                                {article.title}
                              </span>
                            </h3>
                            <p className="card-description">
                              {article.description.replace(/<[^>]*>/g, '').substring(0, 150)}...
                            </p>
                          </div>
                          <div className="card-footer">
                            <div className="post-date">
                              {new Date(article.pubDate).toLocaleDateString()}
                            </div>
                            <div className="post-author">
                              <i className="bi bi-person"></i> {article.author}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PersonSchema />
      </section>
    </DarkBackground>
  );
};

export default Blog; 