import React from 'react';
import '../css/Testimonials.css';
import DarkBackground from './DarkBackground';

const Testimonials = () => {
  const handleLinkedInRedirect = () => {
    window.open('https://www.linkedin.com/in/zeglami/details/recommendations/?detailScreenTabIndex=0', '_blank');
  };

  const testimonials = [
  
    {
      id: 1,
      name: "Odile ",
      position: "Functional Architect, CIMPA PLM Services",
      content: "Abdelhamid is very professional and responsive. I had the opportunity to work with him on a 3DX application for which he successfully contributed to the development of customization (JS, css, etc...)"
    },
    {
      id: 2,
      name: "William ",
      position: "Ingénieur logiciels PLM, Capgemini",
      content: "Abdelhamid is knowledgeable in 3DS application. He is expert on JS and Java and he always give the right expertise on those language."
    },
    {
        id: 3,
        name: "Abdessamad ",
        position: "Software Engineer / Digital transformation",
        content: "From the moment we started working together, I saw on you a competent, methodical person who was very involved in the project. Your technical skills are remarkable, you've demonstrated a perfect mastery of (JS, CSS...) but what really sets you apart, is your ability to solve complex problems creatively and effectively. You were a key member of our development team. It was a real pleasure to work with you on the project."
      },
    {
      id: 4,
      name: "Younes",
      position: "Responsable Contrôle Dépositaire, BCP SECURITIES SERVICES",
      content: "Andelhamid est un professionnel de grande qualité, j'ai eu la chance de travailler avec lui lors de la mise en place du projet contrôle dépositaire au niveau de bcp2s. Il etait indispensable de reconnaître que son implication fût l'élément clé de la réussite de notre projet en un temps record. Son perfectionnisme et sa capacité de réflexion font de lui un collaborateur sur qui vous pouvez vraiment compter."
    }
  ];

  return (
    <DarkBackground>
      <section className="testimonials-section sect-pt4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="title-box-2">
                      <h5 style={{color: "orange"}} className="title-left">
                        Testimonials
                      </h5>
                    </div>
                    
                    {/* Approved Testimonials Section */}
                    {testimonials.map((testimonial) => (
                      <div key={testimonial.id} className="card mb-3">
                        <div className="card-body">
                          <blockquote className="testimonial-quote">
                            "{testimonial.content}"
                          </blockquote>
                          <footer className="testimonial-footer">
                            <strong>{testimonial.name}</strong>
                            <br />
                            <small>{testimonial.position}</small>
                          </footer>
                        </div>
                      </div>
                    ))}

                    {/* LinkedIn Button Section */}
                    <div className="card mt-4">
                      <div className="card-body text-center">
                        <h5 className="card-title">View All Recommendations</h5>
                        <p className="mb-4">
                          See more recommendations and endorsements on LinkedIn
                        </p>
                        <button 
                          className="linkedin-custom-btn"
                          onClick={handleLinkedInRedirect}
                        >
                          <span className="linkedin-icon">
                            <i className="fa fa-linkedin"></i>
                          </span>
                          <span className="linkedin-text">View LinkedIn Profile</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </section>
    </DarkBackground>
  );
};

export default Testimonials; 